<template>
  <div class="apply">
    <invoice-header text="开具电子发票"></invoice-header>
    <div class="apply__detail">
      <div class="apply__detail__row">
        <span>发票详情</span>
      </div>
      <div class="apply__detail__row">
        <span class="apply__detail__row__label">抬头类型</span>
        <span>个人/非企业单位</span>
      </div>
      <div class="apply__detail__row">
        <span class="apply__detail__row__label">发票抬头</span>
        <span>{{head}}</span>
      </div>
      <div class="apply__detail__row">
        <span class="apply__detail__row__label">发票内容</span>
        <span>*信息技术服务*信息服务费</span>
      </div>
      <div class="apply__detail__row">
        <span class="apply__detail__row__label">开票方</span>
        <span>上海喵啰咿科技有限公司</span>
      </div>
      <div class="apply__detail__row">
        <span class="apply__detail__row__label">总金额（元）</span>
        <span v-html="selectOrderTotalMoney" style="color:#e50000">0</span>
      </div>
      <div class="apply__detail__row">
        <span>接收方式</span>
      </div>
      <div class="apply__detail__row">
        <span class="apply__detail__row__label">电子邮件</span>
        <input type="text" v-model="mailbox" placeholder="用于向您发送电子发票" maxlength="50" />
      </div>
      <button @click="onSubmit">提交</button>
    </div>
    <div class="apply__modal" v-show="modalVisible">
      <div class="box">
        <div class="apply__modal__row">
          <div class="close-btn" @click="modalVisible=false"></div>
          <span>开具电子发票</span>
        </div>
        <div class="apply__modal__row">
          <span class="apply__modal__row__label">发票类型</span>
          <span>电子发票</span>
        </div>
        <div class="apply__modal__row">
          <span class="apply__modal__row__label">发票抬头</span>
          <span>{{head}}</span>
        </div>
        <div class="apply__modal__row">
          <span class="apply__modal__row__label">电子邮件</span>
          <span>{{mailbox}}</span>
        </div>
        <div class="apply__modal__tip">
          <p>请确认邮箱无误，提交后无法修改！</p>
          <p>电子发票将在系统开具后发送至您的邮箱，请注意查收</p>
          <p>您的发票抬头为个人抬头</p>
        </div>
        <button @click="onConfirmSubmit">确认提交</button>
      </div>
    </div>
  </div>
</template>

<script>
import { saveInvoice } from "./invoice.js";
import util from '../../commons/util';

export default {
  components: {
    "invoice-header": require("./header").default
  },
  created() {
    let InvoiceInfo = sessionStorage.getItem("InvoiceInfo");
    if (InvoiceInfo) {
      InvoiceInfo = JSON.parse(InvoiceInfo);
      this.pay_nos=InvoiceInfo.pay_nos;
      this.selectOrderTotalMoney=InvoiceInfo.selectOrderTotalMoney;
    }
  },
  data() {
    return {
      modalVisible: false,
      head: GlobalObject.InvoiceUser.realname,
      pay_nos:null,
      selectOrderTotalMoney: "",
      mailbox: ""
    };
  },
  watch: {
    modalVisible(newVal) {
      document.body.style.overflow = newVal ? "hidden" : "auto";
    }
  },
  methods: {
    onSubmit() {
      if (!this.mailbox) {
        return;
      }

      let mailReg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
      if (!mailReg.test(this.mailbox)) {
        util.toast("填写错误，请重新输入");
        return;
      }
      this.modalVisible = true;
    },
    async onConfirmSubmit() {
      let res = await saveInvoice(this.pay_nos, this.mailbox);
      if (res.ret === "0") {
        util.toast(res.message);
        return;
      }
      sessionStorage.setItem("ReturnInfo",JSON.stringify({
        page:"订单",
        routeName:"invoice_order"
      }))
      this.$router.push({ name: "invoice_order_success" });
    }
  }
};
</script>

<style lang="scss">
%row-base {
  height: 0.83rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 0.01rem solid #e6e6e6;
}

%button-base {
  width: 93.333%;
  display: block;
  text-align: center;
  background-color: #20a3dc;
  border-radius: 0.06rem;
  border: none;
  line-height: 1;
  color: #ffffff;
}

.apply {
  &__detail {
    padding-bottom: 0.2rem;

    &__row {
      @extend %row-base;
      font-size: 0.229rem;

      &:first-child,
      &:nth-last-of-type(2) {
        justify-content: center;
        font-size: 0.271rem;
        color: #20a3dc;
      }

      &__label {
        margin: 0 0.3rem;
      }

      input {
        flex: 1;
        border: none;
        padding: 0;
        outline: none;
      }
    }

    button {
      @extend %button-base;
      height: 0.8rem;
      margin: 0.51rem auto;
      font-size: 0.375rem;
    }
  }

  &__modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);

    .box {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: #fff;
    }

    &__row {
      @extend %row-base;
      font-size: 0.229rem;

      &:first-child {
        justify-content: center;
        font-size: 0.312rem;
        color: #20a3dc;
      }

      .close-btn {
        width: 0.37rem;
        height: 0.37rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url(./images/close.png);
        position: absolute;
        left: 0.3rem;
        cursor: pointer;
      }

      &__label {
        margin: 0 0.3rem;
      }
    }

    &__tip {
      margin: 0.3rem;
      font-size: 0.208rem;
      line-height: 0.48rem;
      color: #999999;
    }

    button {
      @extend %button-base;
      height: 0.84rem;
      font-size: 0.375rem;
      margin: 0 auto 0.3rem auto;
    }
  }
}
</style>
